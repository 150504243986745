<template>
  <q-footer class="bg-white shadow-3 mobile-footer">
    <q-tabs active-color="primary" class="text-grey full-width" indicator-color="transparent" align="justify">
      <q-route-tab name="home" :label="$t('discovery')" icon="fal fa-search-location" :to="{ name: 'home' }" />
      <q-route-tab name="journeys" :label="$tc('journey.journey', 2)" icon="fal fa-route" :to="{ name: 'Journeys' }" />
      <q-route-tab v-if="showTickets" name="tickets" :label="$tc('tickets.default', 2)" icon="fal fa-ticket-alt" :to="{name: 'Tickets'}" />
      <q-route-tab name="profile" :label="$t('account')" icon="fal fa-user" :to="{name: 'profile'}" />
    </q-tabs>
  </q-footer>
</template>

<script>
export default {
  computed: {
    showTickets () {
      return ['gohi', 'swift'].includes(this.$store.getters.partner.slug)
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-footer
  padding-bottom env(safe-area-inset-bottom)

.q-footer--hidden
  .option-button .q-btn
    display none

.q-tabs >>> .q-tabs__content
  overflow visible !important

.q-tabs >>> .q-tab
  padding-top 4px

@media (max-width $breakpoint-xs-max)
  .q-tabs >>> .q-tab__label
    font-size: 11px;
  .q-tab
    padding: 0
    text-transform none
  .q-tab--full
    min-height 0
</style>
